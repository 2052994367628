.burgerContainer {
  height: 48px;
  display: flex;
  position: absolute;
  top: 30px;
  right: 2rem;
  align-items: center;
  z-index: 10;

  a {
    padding-right: 12px;
    border-right: 2px solid #000000;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    font-size: 24px;
    margin: 10px;
    color: #000000;
    font-weight: bold;
  }


  .burgerButton {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 20px;
    height: 20px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding-left: 6px;

  }

  &:focus {
    outline: none;
    position: fixed;
  }



  div {
    width: 20px;
    height: 3px;
    background: #000;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
}
.burgerContainerHomepage {
  div {
    background: #fff;
  }
  a{
    border-right: 1px solid #FFFFFF;
    color: #FFFFFF;
  }
}


@media (max-width: 961px) {
  .burgerContainer a{
    display: none;
  }
  .burgerContainer {
    top: 20px;
  }
}
