.home {
  .homeImage {
    margin-top: -136px;
    background-image: url("../../../assets/images/mainpage_image_new.jpg"); // Fallback for old browsers
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 52.15%,
        rgba(0, 0, 0, 0.8) 100%
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0) 52.15%, rgba(0, 0, 0, 0.8) 100%),
      url("../../../assets/images/mainpage_image_new.jpg");
    height: 768px;
    width: 100%;
    background-position: 68% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    position: absolute;
  }

  h2 {
    font-size: 35px;
    line-height: 42px;
    text-align: center;
  }
  h3 {
    font-size: 26px;
    line-height: 31px;
    text-align: center;
  }
}

@media (max-width: 961px) {
  .home {
    .homeImage {
      margin-top: -90px;
      height: 418px;
    }
    h2 {
      font-size: 22px;
      line-height: 26px;
    }
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
