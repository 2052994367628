.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //border-bottom: 2px solid #eeeeee;
}
.headerHomepage {
  border-bottom: none;
}

.headerNavbar {
  background: rgba(0, 0, 0, 0.8);
  height: 100vh;
  text-align: left;
  padding-top: 78px;
  position: fixed;
  width: 377px;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  z-index: 1;

  div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 32px;
    padding: 2rem;
  }

  h3,
  a {
    font-size: 24px;
    margin: 10px;
    color: #FFFFFF;
    font-weight: bold;
  }
}

.headerLogoWrapper {
  margin: 0 auto;
}

.headerLogo {
  width: 134px;
  height: 47px;
  margin: 20px auto;
}

// Desktop settings
@media (min-width: 961px) {
  .headerLogoWrapper {
    margin: 0;
  }
  .headerLogo {
    margin: 34px;
  }
}

@media (max-width: 599px) {

  .headerNavbar {
    width: 100%;

    a {
      text-align: center;
    }
  }
}


