.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

html {
  background: #f9f9f9;
}

.container {
  width: 960px;
  margin: 0 auto;
}

.card {
  background: #fff;
  border: 1.25px solid #F2F2F2;
  border-radius: 5px;

}

@media (max-width: 800px) {
  .container {
    width: auto;
    margin: 0;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noscrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.noscrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* GLOBAL STYLES */

h1 {
  font-family: Replica;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;

  /* Gray 4 */
  color: #000000;
  margin: 0;
  padding: 0;
  text-align: left;
}

h2 {
  font-family: Replica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  /* identical to box height */
  color: #000000;
  margin: 0;
  padding: 0;
  text-align: left;
}

h3 {
  font-family: Replica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  /* identical to box height */
  color: #000000;

  margin: 0;
  padding: 0;
  text-align: left;
}

p {
  font-family: Replica;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 13px;

  /* identical to box height */
  color: #000000;

  margin: 0;
  padding: 0;
  text-align: left;
}

a {
  font-family: Replica;
  text-decoration: none;
  font-style: inherit;
  font-weight: normal;
  font-size: inherit;
  line-height: inherit;

  /* identical to box height */
  color: #3366bb;

  margin: 0;
  padding: 0;
  text-align: left;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.wordBreak {
  word-break: break-word;
}

.enterWrap {
  white-space: pre-wrap;
}
