.datepickerInput {
  background: url(../../assets/icons/dropdown_arrow_black.svg); /* Gray 6 */
  background-size: 10px 10px;
  background-position: center right 6px;
  background-repeat: no-repeat;

  height: 43px;
  width: calc(100% - 12px); /*6px padding in each side of input field */

  padding: 0 6px;

  font-family: Replica;
  font-style: normal;
  font-size: 16px;
  line-height: 13px;
  /* width: 150px; */

  outline: none;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  box-shadow: none;
  display: block;
}
.react-datepicker-wrapper {
  display: block;
}
.react-datepicker__input-container {
  display: block;
}

.react-datepicker {
  font-family: Replica;
  font-style: normal;

  border: 1.25px solid #f2f2f2;
}

.react-datepicker__time-container {
  border-left: 1.25px solid #f2f2f2;
}

.react-datepicker-time__header {
  font-weight: 500;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: white;
}

.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: #f2f2f2;
}
.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__header {
  background-color: white;
  border: none;
  border-bottom: 1.25px solid #f2f2f2;
}

.react-datepicker__day-name {
  color: #cccccc;
}

.react-datepicker__day--selected {
  background-color: #5c8aff;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #5c8aff;
}

.react-datepicker__current-month {
  font-weight: 500;
}
/* .react-datepicker__month--selecting-range {
  background-color: #216ba5;
  color: #fff;
} */

.react-datepicker__month--selecting-range .react-datepicker__day--in-range {
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--in-selecting-range {
  background-color: #216ba5;
  color: #fff;
}