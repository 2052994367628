.footer {
  background-color: #1b1b1b;
  margin-top: auto;
  p {
    color: white;
    font-size: 18px;
  }

  a {
    color: #ec2b34;
    font-size: 18px;
    font-family: ReplicaBold;
  }
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 50px;
  align-items: flex-end;
  background-color: #1b1b1b;
}

.footerInfo {
  display: flex;
  flex-direction: row;
}

.footerBar {
  margin-left: 10px;
  margin-right: 10px;
}

.footerColumn {
  display: flex;
  flex-direction: column;
}

.footerPolicy {
  display: inline;
  font-size: 16px;
  color: white;
  font-family: Replica;
  text-decoration: underline;
}

.footerEnd {
  text-align: right;
}

.footerWeknowit {
  background-color: #1b1b1b;

  p {
    display: inline;
    font-size: 16px;
    color: white;
    font-family: Replica;
  }

  a {
    font-size: 16px;
    text-decoration: underline;
    color: white;
    font-family: Replica;
  }
}

@media (max-width: 961px) {
  .footerContainer {
    padding: 0;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .footerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: auto;
  }

  .footerColumn {
    align-items: center;
    padding-top: 40px;
  }

  .footerEnd {
    padding-top: 30px;
    padding-bottom: 40px;
    text-align: center;
  }
}